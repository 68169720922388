<template>
  <!-- Final -->
  <section>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="th_per1 mtwidth">MATRÍCULA</th>
            <th class="th_per1">NOME DO ALUNO</th>

            <th class="th_per1" v-if="tipoTabela == 2 || estiloTabelaEJA != null">1° Período</th>
            <th class="th_per1" v-if="tipoTabela == 2 || estiloTabelaEJA != null" >2° Período</th>

            <th class="th_per1" v-if="tipoTabela == 2 && estiloTabelaEJA == null">3° Período</th>
            <th class="th_per1" v-if="tipoTabela == 2 && estiloTabelaEJA == null">4° Período</th>
            <th class="th_per1" v-if="tipoTabela == 2 && estiloTabelaEJA == null">Total</th>

            <th class="th_per1" v-if="tipoTabela == 1 && estiloTabelaEJA == null">1° Semestre</th>
            <th class="th_per1" v-if="tipoTabela == 1 && estiloTabelaEJA == null">2° Semestre</th>

            <!-- Todos tem recuperacao final, menos o eja -->
            <th class="th_per1" style="width: 15%;"  v-if="estiloTabelaEJA == null && this.configuracao.info.tera_recuperacao">RECUPERAÇÃO FINAL <br>(NOTA ENTRE 0-100)</th>
            <!-- Todos tem media final -->
            <th class="th_per1">MÉDIA FINAL</th>
          </tr>
        </thead>
        <tbody>




          <tr v-for="(record, index) of this.notasAlunos" :key="index">



            <td class="tdmt">{{record.matricula}}</td>
            <td>
                <div class="container">
                <div class="row">
                    <div class="col-md-12">
                      <p>
                        {{record.aluno_completo.nome}} {{record.aluno_completo.sobrenome}}
                        <div v-if="record.aluno_fora_da_turma == true">
                            <span class="badge bg-danger table_style ml-2"  v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Transferido'">Transferido - {{record.evento_saida[record.evento_saida.length - 1].data_formato_br}}</span>
                            <span class="badge bg-danger table_style ml-2"  v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Evadido'"  >Evadido</span>
                            <span class="badge bg-danger table_style ml-2"  v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Faleceu'"  >Falecido</span>
                            <va-popover style="cursor: pointer;" class="badge bg-danger table_style ml-2"
                            :message="'Mudou para turma: ' + record.evento_saida[record.evento_saida.length-1].turma_nova.nome" color="danger"
                            v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Transferencia de Turma'">
                            Mudou de turma - {{record.evento_saida[record.evento_saida.length - 1].data_formato_br}}
                            </va-popover>
                        </div>
                        <span class="badge"  v-if="record.nee">
                            <va-popover
                            class="mr-2 mb-2"
                            message="Adicionar métodos utilizados para avaliar o aluno com NEE."
                            color="primary"
                            placement="right"
                            style="background-color: #141313;"
                            >
                            <va-button v-if="acesso.cadastrar" color="#4589df00" @click="ajustarInfo(record.aluno_id)">
                                NEE
                            </va-button>
                            <va-button v-else color="#4589df00">
                                NEE
                            </va-button>
                            </va-popover>
                        </span>
                      </p>
                    </div>
                </div>
                </div>
            </td>




            <!-- eja -->
            <td style="width: 10%;"  v-if="estiloTabelaEJA != null">
                <div v-if="record['media'+1] >= configuracao.info.notas.media">
                    <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                        {{record['media'+1]}}
                    </h3>
                </div>
                <div v-else>
                    <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;">
                        {{record['media'+1]}}
                    </h3>
                </div>
            </td>
            <td style="width: 10%;"  v-if="estiloTabelaEJA != null">
                <div v-if="record['media'+2] >= configuracao.info.notas.media">
                    <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                        {{record['media'+2]}}
                    </h3>
                </div>
                <div v-else>
                    <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;">
                        {{record['media'+2]}}
                    </h3>
                </div>
            </td>

            <!-- bimestral -->

            <td style="width: 10%;" v-if="tipoTabela == 2 && estiloTabelaEJA == null">
                <div v-if="record.media1 >= this.configuracao.info.notas.media">
                    <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight:bold;font-size:18px;" >
                      {{record.media1}}
                    </h3>
                </div>
                <div v-else>
                    <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;">
                      {{record.media1}}
                    </h3>
                </div>
            </td>
            <td style="width: 10%;"  v-if="tipoTabela == 2 && estiloTabelaEJA == null">
                <div v-if="record.media2 >= this.configuracao.info.notas.media">
                    <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                      {{record.media2}}
                    </h3>
                </div>
                <div v-else>
                    <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;">
                      {{record.media2}}
                    </h3>
                </div>
            </td>
            <td style="width: 10%;"  v-if="tipoTabela == 2 && estiloTabelaEJA == null">
              <div v-if="record.media3 >= this.configuracao.info.notas.media">
                    <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;" >
                      {{record.media3}}
                    </h3>
                </div>
                <div v-else>
                    <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;" >
                      {{record.media3}}
                    </h3>
                </div>
            </td>

            <td style="width: 10%;"  v-if="tipoTabela == 2 && estiloTabelaEJA == null">
              <div v-if="record.media4 >= this.configuracao.info.notas.media">
                    <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                      {{record.media4}}
                    </h3>
                </div>
                <div v-else>
                    <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;">
                      {{record.media4}}
                    </h3>
                </div>
          </td>
            <td style="width: 10%;"  v-if="tipoTabela == 2 && estiloTabelaEJA == null">
            <div v-if="record.total >= 200">
                  <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                      {{record.total}}
                  </h3>
              </div>
              <div v-else>
                  <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;">
                      {{record.total}}
                  </h3>
              </div>
            </td>

            <!-- semestral -->

            <td style="width: 10%;"   v-if="tipoTabela == 1 &&  estiloTabelaEJA == null">
                <div v-if="record.mediaSemestre1 >= configuracao.info.notas.media">
                    <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                      {{record.mediaSemestre1}}
                    </h3>
                </div>
                <div v-else>
                    <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;">
                      {{record.mediaSemestre1}}
                    </h3>
                </div>
            </td>
            <td style="width: 10%;"   v-if="tipoTabela == 1 && estiloTabelaEJA == null">
                <div v-if="record.mediaSemestre2 >= configuracao.info.notas.media">
                    <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                      {{record.mediaSemestre2}}
                    </h3>
                </div>
                <div v-else>
                    <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;">
                      {{record.mediaSemestre2}}
                    </h3>
                </div>
            </td>
             <td style="width: 10%;" v-if="estiloTabelaEJA == null && this.configuracao.info.tera_recuperacao">
                <div v-if="!record.temRecuperacao">
                  <input
                    v-if="!record.aluno_fora_da_turma && !record.nee && acesso.cadastrar"
                    min="0" max="100"
                    @blur="adicionarEditarRecuperacaoFinal(record, record.recuperacaoFinal, 2)"
                    style="background-color: #5f9e8e38;"
                      type="number"
                      class="form-control"
                      v-model="record.recuperacaoFinal"
                      placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record.recuperacaoFinal" class="form-control" v-else/>
                </div>
                <div v-else>
                  <input v-if="!record.aluno_fora_da_turma && acesso.editar"
                    min="0" max="100"
                    @blur="adicionarEditarRecuperacaoFinal(record, record.recuperacaoFinal, 2)"
                    style="background-color:rgb(224 214 216);"
                    type="number"
                    class="form-control"
                    placeholder="Digite a nota"
                    v-model="record.recuperacaoFinal"
                  >
                  <input type="text" disabled :value="record.recuperacaoFinal" class="form-control" v-else/>
                </div>
              </td>

              <td style="width: 10%; text-align:center;">
                <h3 v-if="record.mediaFinal >= this.configuracao.info.notas.media" style="text-align: center;color: #5a79ec;font-weight: bold;">
                  {{record.mediaFinal}}
                </h3>

                <va-popover v-if="record.mediaFinal < this.configuracao.info.notas.media && record.passou == 0"
                    class="mr-12 mb-12"
                    placement="left"
                    message="Clique aqui para alterar de RETIDO para PROGRESSÃO PARCIAL!"
                  >
                  <Button     @click="mudarParaRetidoOuDependencia(record, 1)"
                    style="background:transparent;border:none!important;font-size:0;">
                    <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;">
                      {{record.mediaFinal}}
                    </h3>
                  </Button>
                </va-popover>

                <va-popover v-if="record.mediaFinal < this.configuracao.info.notas.media && record.passou == 2"
                    class="mr-12 mb-12"
                    placement="left"
                    message="Clique aqui para alterar de PROGRESSÃO PARCIAL para RETIDO!"
                  >
                  <Button @click="mudarParaRetidoOuDependencia(record, 2)" style="background:transparent;border:none!important;font-size:0;">
                    <h3 style="text-align:center;color:#eab308;font-weight: bold;">
                      {{record.mediaFinal}}
                    </h3>
                  </Button>
                </va-popover>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>

export default {
  name: "FinalTable",
  props: {
    displayedRecords:[],
    ajustarInfo:[],
    acesso:[],
    periodoSelecionado:'',
    encerrado:[],
    configuracao:null,
    estiloTabelaEJA:null,

    //nee
    formAlunoComNEE: null,
    showModal:false,
  },
  data() {
    return {
      calendario: "",
      tipoTabela:0,
      notasAlunos:[],
    };
  },
  methods: {
    verificarEditarNee(n){
      this.$emit('verificarEditarNee', n);
    },
    alunocomNEE(n){
      this.$emit('alunocomNEE', n);
    },

    ajustarInfo(aluno_id){
      this.$emit('ajustarInfo', aluno_id);
    },
    adicionarEditarSimulado(aluno_id_selecionado, nota, qualTipo, idSimulado, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarSimulado', aluno_id_selecionado, nota, qualTipo, idSimulado, configuracao,periodoSelecionado);
    },
    adicionarEditarAtividade(aluno_id_selecionado, nota, qualTipo, idAtividade, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarAtividade', aluno_id_selecionado, nota, qualTipo, idAtividade, configuracao, periodoSelecionado);
    },
    adicionarEditarAvaliacao(aluno_id_selecionado, nota, qualTipo, idAvaliacao, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarAvaliacao', aluno_id_selecionado, nota, qualTipo, idAvaliacao, configuracao, periodoSelecionado);
    },
    adicionarEditarRecuperacao(aluno_id_selecionado, nota, qualTipo, idRecuperacao, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarRecuperacao', aluno_id_selecionado, nota, qualTipo, idRecuperacao, configuracao, periodoSelecionado);
    },
    mudarParaRetidoOuDependencia(record, tipo){
      this.$emit('mudarParaRetidoOuDependencia', record, tipo);
    },
    adicionarEditarRecuperacaoFinal(record, recuperacao, tipo){
      this.$emit('adicionarEditarRecuperacaoFinal', record, recuperacao, tipo);
    },

  },
  beforeMount() {
    this.notasAlunos = [];
    this.notasAlunos = this.displayedRecords;
    // 0 == recuperacao semestral, 1 == recuperacao bimestral
    this.tipoTabela = (this.configuracao.info.notas.recuperacao_bimestral == 0 ? 1 : 2);
  },
};
</script>


<style>
  @media(max-width:576px){
    .va-modal__container{
      width:100% !important;
      height:45%;
    }
    .va-modal--mobile-fullscreen {
      min-height: 0vh!important;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa;
    color: #495057;
    border-right: 1px solid #e9ecef;
    border-bottom: 2px solid #e9ecef;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
    vertical-align: middle;
  }

  .th_esp{
    width:15px;
    background-color: #3f448700;
    border-bottom: 2px solid #fff;
  }
  .cabecario-turma{
    background-color:#4da4e9;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .tbl_situacao_apv{
    font-weight: 600;
    color: #539b53;
  }
  .tbl_situacao_rep{
    font-weight: 600;
    color: #b93b3b;
  }
</style>

<style scoped>

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 23px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .mtwidth{
    width:10%;
  }
  .tdmt{
    text-align: center;font-weight: 800;color: #2b72fd;
  }

  tr td{
    vertical-align: middle;
  }


</style>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .th_prf{
    background-color: #0067a7 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
  }
  .th_prf1{
    background-color:  #2e92d1 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
  }
  .tbody_prf{
    border-top: 0px solid currentColor !important;
  }
</style>

<style>
 .compl{font-size: 12px !important;}
</style>
