import axios from 'axios'

export class Nee {
  static async obtemTodos () {
    return axios.get('/nee')
  }
  static async obtemUmSemId (payload) {
    return axios.get('nee/buscar/semId?aluno_id='+payload.aluno_id+'&nota_config_id='+payload.nota_config_id+'&periodo='+payload.periodo+'&ano='+payload.ano+'&tipo='+payload.tipo);
  }
  static async cadastrar (payload) {
    return axios.post('/nee', payload);
  }
  static async alterar (payload) {
    return axios.put('nee/'+payload.id, payload);
  }
  static async remover (id) {
    return axios.delete('/nee/'+id)
  }
  
}
